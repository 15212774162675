import { useCallback, useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import _ from 'lodash';

import ModalBase from '../../../../../components/Modals/ModalBase';
import { getProducts, getProductsInModel } from './services';
import { generatePathRegisterFile } from '../../../../../helpers/general';
import { Container } from './style';
import { type ClothingModelCreationType } from '../../../../../types/ClothingModelType';

type RawMaterialModelsModalProps = {
  model?: ClothingModelCreationType;
  handleModels: (id: number) => void;
  setModels: (productIds: number[]) => void;
};

export function RawMaterialModelsModal({ model, handleModels, setModels }: RawMaterialModelsModalProps) {
  const [open, setOpen] = useState(false);

  const [products, productInModel] = useQueries([
    {
      queryKey: ['products'],
      queryFn: async () => await getProducts.execute(),
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true
    },
    {
      queryKey: ['productsInModel', model?.id],
      queryFn: async () => await getProductsInModel.execute(model?.id),
      enabled: !_.isEqual(model?.id, -1),
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true
    }
  ]);

  const handleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(productInModel.data)) {
      setModels(productInModel.data ?? []);
    }
  }, [productInModel.data, setModels]);

  if (_.isEmpty(products.data)) {
    return null;
  }

  return (
    <>
      <ModalBase
        visible={open}
        hideFooter
        disableClickOutsideToClose
        title='Adicionar insumos ao modelo'
        style='primary'
        handleClose={handleOpen}
        handleConfirm={() => {}}
      >
        <Container>
          {products.data?.map(product => {
            const activeProduct = model?.productsIds?.includes(product?.id);

            return (
              <div
                key={product.id}
                className='d-flex justify-content-between align-items-center p-2'
                style={{ borderBottom: '1px #f1f1f1 solid' }}
              >
                <div className='d-flex align-items-center'>
                  <img
                    src={generatePathRegisterFile({ filename: product.photo })}
                    alt={product.title}
                    className='mr-2'
                    style={{ width: 60, height: 60 }}
                  />
                  <div>
                    <h4 className='mb-0'>{product.title}</h4>
                    <p className='mb-0'>{product.description}</p>
                  </div>
                </div>
                <div>
                  <button
                    type='button'
                    className={`btn ${activeProduct ? 'btn-danger' : 'btn-success'}`}
                    style={{ width: 40, height: 40 }}
                    onClick={() => {
                      handleModels(product.id);
                    }}
                  >
                    {activeProduct ? <i className='fas fa-times' /> : <i className='fas fa-plus' />}
                  </button>
                </div>
              </div>
            );
          })}
        </Container>
      </ModalBase>

      <button type='button' className='btn btn-warning position-relative ml-2' onClick={handleOpen} disabled={open}>
        Insumos
        <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary'>
          {model?.productsIds?.length ?? 0}
        </span>
      </button>
    </>
  );
}
